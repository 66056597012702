<template>
  <!-- 仓库管理管理 -->
  <div class="contractTemplate">
    <!-- 搜索筛选 -->
    <FormSearch
      :getdata="getdata"
      :form-inline="formInline"
      :form-item-arr="formItemArr"
    >
      <template>
        <el-form-item
          label="仓库名称"
          prop="warehouseName"
        >
          <el-select
            v-model="formInline.warehouseName"
            class="cmpNameSearch"
            filterable
            remote
            reserve-keyword
            placeholder="请输入关键字搜索"
            :remote-method="warehouseNameremoteMethod"
          >
            <el-option
              v-for="item in warehouseNameArr"
              :key="item.warehouseName"
              :label="item.warehouseName"
              :value="item.warehouseName"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="所属企业"
          prop="cmpCode"
        >
          <el-select
            v-model="formInline.cmpCode"
            class="cmpNameSearch"
            filterable
            remote
            reserve-keyword
            placeholder="请输入关键字搜索"
            :remote-method="remoteMethod"
          >
            <el-option
              v-for="item in cmpNameArr"
              :key="item.cmpCode"
              :label="item.cmpName"
              :value="item.cmpCode"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="所在地区">
          <el-cascader
            v-model="formInline.address"
            :props="{ label: 'name', value: 'name', children: 'children' }"
            :options="allCityOption"
            @change="getCity"
          />
        </el-form-item>
      </template>
    </FormSearch>
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <div class="list-operation">
        <el-button size="small" type="primary" @click="editAndAddRow">
          添加仓库
        </el-button>
      </div>
      <Table
        :item-data="itemData"
        :list-data="listData"
        :operation-button="operationButton"
        :loading="loading"
      />
      <!-- 分页 -->
      <Pagination
        :params="formInline"
        :total="total"
        :in-article="listData.length"
        :get-data-list="getdata"
      />
    </div>
    <!-- 弹出的新增跟修改框 -->
    <!-- <Dialog
      ref="dialog"
      :edit-form-data="editFormData"
      :form-item="addAndEditFormItem"
      dialog-width="50%"
      column="2"
      @getFormData="getFormData"
    >
      <template slot="dynamicInsert">
        <el-form-item
          label="所属企业"
          prop="cmpCode"
          :rules="[
            { required: true, message: '请输入选择', trigger: 'change' }
          ]"
        >
          <el-select
            v-model="editFormData.cmpCode"
            class="cmpNameSearch"
            filterable
            remote
            reserve-keyword
            :disabled="disabled"
            placeholder="请输入关键字搜索"
            :remote-method="remoteMethod"
          >
            <el-option
              v-for="item in cmpNameArr"
              :key="item.cmpCode"
              :label="item.cmpName"
              :value="item.cmpCode"
            />
          </el-select>
        </el-form-item>
      </template>
    </Dialog> -->
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch'
import Table from '@/components/Table'
import Pagination from '@/components/Pagination2'
// import Dialog from '@/components/Dialog'
import {
  wmsWarehousePage,
  warehouseUpdateAndAdd,
  warehouseEnableOrDisable,
  warehouseList
} from '@/api/warehousingSystem'
import { getSelectAreaTree } from '@/api/login'
import { getCmpBaseinfoList } from '@/api/memberManagement'
// import { rules } from '@/utils/matchFormRegex'
export default {
  components: { FormSearch, Table, Pagination },
  data() {
    return {
      cmpNameArr: [],
      warehouseNameArr: [],
      editFormData: {},
      itemData: [
        { label: '仓库名称', prop: 'warehouseName', width: 180 },
        { label: '所属企业', prop: 'cmpName', width: 180 },
        { label: '所在地区', prop: 'allCity', width: 180 },
        { label: '联系人', prop: 'linkMan', width: 180 },
        { label: '联系电话', prop: 'linkPhone', width: 180 },
        { label: '创建时间', prop: 'createTime', width: 180 },
        {
          label: '仓库状态',
          prop: 'state',
          width: 100,
          child: [
            { value: '01', label: '有效' },
            { value: '02', label: '禁用' }
          ]
        }
      ],
      operationButton: [
        { bType: 'primary', label: '编辑', handleEvent: this.editAndAddRow },
        {
          num: '02',
          val: 'state',
          bType: 'primary',
          label: '启用',
          handleEvent: this.enableDisableRow
        },
        {
          num: '01',
          val: 'state',
          bType: 'danger',
          label: '禁用',
          handleEvent: this.enableDisableRow
        },
        { bType: 'primary', label: '详情', handleEvent: this.viewDetails }
      ],
      formItemArr: [
        {
          type: 'select',
          label: '状态',
          value: 'state',
          pLabel: 'label',
          pValue: 'value',
          child: [
            { value: '01', label: '有效' },
            { value: '02', label: '禁用' }
          ]
        }
      ],
      loading: false,
      total: 0,
      disabled: false,
      listData: [],
      formInline: {
        pageSize: 10,
        pageNum: 1
      },
      allCityOption: []
    }
  },
  mounted() {
    // getSelectAreaTree('00', res => {
    //   this.addAndEditFormItem.map(item => {
    //     if (item.value === 'allCity') {
    //       item.options = [...res.data]
    //     }
    //   })
    // })
    getSelectAreaTree('00', res => {
      this.allCityOption = [...res.data]
    })
  },
  methods: {
    // 地区级联事件
    getCity(arr) {
      if (arr.length !== 3) return
      this.formInline.placeProvince = arr[0]
      this.formInline.placeCity = arr[1]
      this.formInline.placeTown = arr[2]
    },
    // 联动搜索仓库列表
    warehouseNameremoteMethod(value) {
      warehouseList(value || '', res => {
        this.warehouseNameArr = res.data
      })
    },
    // 联动搜搜货主列表
    remoteMethod(value) {
      getCmpBaseinfoList(value || '', res => {
        this.cmpNameArr = res.data.filter(item => item.cmpRoleList.includes('09'))
      })
    },
    // 获取列表数据
    getdata(type) {
      if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1
        }
      } else if (!type) {
        this.formInline.pageNum = 1
      }
      wmsWarehousePage(this.formInline, res => {
        this.listData = [...res.data.pageData]
        this.listData.map((item) => {
          item.allCity = item.placeProvince + '/' + item.placeCity + '/' + item.placeTown
        })
        this.total = res.data.total
      })
    },
    // 新增或修改数据
    editAndAddRow(row) {
      this.disabled = row.constructor === Object
      let type = ''
      let path = ''
      if (row.constructor === Object) {
        row.allCity = [row.placeProvince, row.placeCity, row.placeTown]
        this.editFormData = { ...row }
        type = 'edit'
        path = '/warehousingSystem/editWarehouse'
      } else {
        this.editFormData = {}
        type = 'add'
        path = '/warehousingSystem/addWarehouse'
      }
      this.$store.commit('setResourceByTaskObj', { ...this.editFormData })
      this.$router.push({
        path: path,
        query: { type }
      })
    },
    // 启用与禁用
    enableDisableRow(row) {
      const text = row.state === '01' ? '禁用后，该仓库将无法正常操作' : '启用后，该仓库将可以正常使用'
      this.$confirm(text, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          warehouseEnableOrDisable(
            { id: row.id, state: row.state === '01' ? '02' : '01' },
            res => {
              this.$message.success('成功！')
              this.getdata(true)
            }
          )
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    // 获取到输入框的内容
    getFormData(value) {
      if (value.allCity.length !== 3) {
        this.$message.error('请选择省市区')
        return
      }
      value.placeProvince = value.allCity[0]
      value.placeCity = value.allCity[1]
      value.placeTown = value.allCity[2]
      value.cmpCode = this.editFormData.cmpCode
      warehouseUpdateAndAdd(value, () => {
        this.getdata(value.id)
        this.$message.success('成功！')
        this.$refs.dialog.editFormVisible = false
      })
    },
    viewDetails(row) {
      row.allCity = row.placeProvince + row.placeCity + row.placeTown
      this.$store.commit('setResourceByTaskObj', { ...row })
      this.$router.push('/warehousingSystem/storehouseDetails')
    }
  }
}
</script>

<style scoped>
.cmpNameSearch {
  width: 100%;
}
.marginTop {
  padding-top: 18px;
}
</style>
